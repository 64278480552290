@import "../../constants//style-variables.scss";
.create-auction {
  padding: 0px 50px;
  height: 88vh !important;

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: black !important;
  }

  .ant-input[disabled] {
    color: black !important;
  }

  .ant-checkbox-disabled .ant-checkbox-input {
    color: black !important;
  }

  .create-auction-title {
    color: $primary-font-blue;
    font-size: 40px;
    font-weight: 300;
    display: flex;
    padding-left: 140px;
  }
  .auction-form-container {
    display: flex;
    gap: 10px;
    // justify-content: center;
    margin-top: 10px;
    .container-1 {
      width: 17%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .container-2 {
      width: 75%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .form-items {
        .noteText {
          padding-top: 25px;
          font-size: 13px;
        }
      }
      .slots-container {
        height: 300px;
        width: 110%;
        padding: 20px 15px;
        border-radius: 20px;
        box-shadow: 4px 4px 11px -8px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background-color: white;
        .no-slots-container {
          text-align: center;
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          color: $filter-head-grey;
        }
      }
    }
    .container-3 {
      width: 30%;
      .terms-container {
        background-color: white;
        border-radius: 20px;
        padding: 10px 15px;
        height: calc(100vh - 250px);
        .terms-and-conditions {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 5px;
        }
        .terms {
          overflow: auto;
          height: 92%;
        }
      }
    }

    .form-items {
      display: flex;
      flex-direction: column;
      color: $form-label-grey;
      &.align-right {
        align-items: flex-end;
        width: 100px;
        gap: 6px;
        .label {
          padding: 0px;
        }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      .label {
        padding: 0 10px;
      }
      .label-hub-select {
        width: 200px;
      }
      .label-hub {
        padding: 20px 10px 5px 10px;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 20px;
      }
      .ant-input {
        border-radius: 15px;
      }
      .ant-picker {
        border-radius: 15px;
      }
    }
  }
  .bottom-container {
    display: flex;
    // padding-left: 38%;
    // margin-top: -25px;
    // padding-left: 7%;
    margin-top: -45px;
    .ant-btn {
      border-radius: 20px;
      background-color: $primary-font-blue;
      color: white;
      border-color: transparent;
    }
  }
}
