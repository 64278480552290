@import "../../constants/style-variables.scss";

// .details {
//   // background-color: $background-grey;
//   // height          : 100vh;

  .details-header {
    color           : $primary-font-blue;
    height          : 45px;
    background-color: white;
    width           : 100%;
    display         : flex;
    gap             : 10px;
    font-size       : 16px;
    padding         : 10px 17px;
    box-shadow      : 0px 1px 14px -8px;

    .back-btn {
      font-size  : 24px;
      margin-top : -8px;
      font-weight: 900;
      cursor     : pointer;
    }

    .title {
      font-weight: 700;
    }
  }

  .registration-page {
    display: flex;

    // justify-content: space-between;
    .left-container {
      display              : flex;
      flex-direction       : column;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select  : none;
      /* Safari */
      -khtml-user-select   : none;
      /* Konqueror HTML */
      -moz-user-select     : none;
      /* Old versions of Firefox */
      -ms-user-select      : none;
      /* Internet Explorer/Edge */
      user-select          : none;

      /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
      .title {
        font-size     : 22px;
        font-weight   : 700;
        padding       : 30px 0px;
        display       : flex;
        flex-direction: column;
        align-items   : center;
        width         : 200px;
      }

      .avatar-container {
        cursor        : pointer;
        display       : flex;
        flex-direction: column;
        align-items   : center;
        gap           : 10px;
        width         : 200px;
        font-size     : 16px;
        font-weight   : 500;

        .ant-avatar {
          color     : #d2d2d8;
          background: #fff;
        }
      }

      .nav-container {
        margin: 40px 0;

        nav {
          // position: absolute;
          display: grid;

          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          .nav-items {
            color          : rgb(190, 190, 190);
            text-align     : center;
            text-decoration: none;
            width          : 100%;
            padding        : 1em 0;
            display        : flex;
            font-weight    : 400;
            cursor         : pointer;

            &.active {
              font-weight: bold;
              color      : black;
            }

            .nav-item-icon {
              width : 22px;
              height: 22px;
              margin: 0em 1em 0em 2em;
            }
          }

          .line {
            width           : 3px;
            height          : 35px;
            margin-top      : -140px;
            background-color: black;
            transition      : all 0.5s ease-in-out;
          }
          .line-existing{
            width           : 3px;
            height          : 35px;
            margin-top      : -40px;
            background-color: black;
            transition      : all 0.5s ease-in-out;
          }
          .two.line {
            transform: translateY(50px);
          }

          .three.line {
            transform: translateY(100px);
          }

          .four.line {
            transform: translateY(150px);
          }

          // .five.line {
          //   transform: translateY(200px);
          // }
        }
      }

      .archive-container {
        display       : flex;
        flex-direction: column;
        align-items   : center;
        color         : white;
        margin        : 30px 0;

        .archive {
          background-color: #14121f;
          width           : 150px;
          // height: 90px;
          height          : 45px;
          border-radius   : 25px;
          display         : flex;
          justify-content : space-between;
          // padding: 15px;
          padding         : 10px 15px;
          cursor          : pointer;

          .left {
            display        : flex;
            flex-direction : row-reverse;
            justify-content: space-between;
            width          : 100%;
            line-height    : 23px;

            .archive-icon {
              width : 25px;
              height: 25px;
              fill  : white;

              svg {
                width : 25px !important;
                height: 25px !important;
              }
            }

            .head {
              font-size: 14px;
            }

            .sub-head {
              margin-top: -6px;
              font-size : 9px;
            }
          }
        }
      }
    }

    .right-container {
      padding: 20px;

      .crop-select-container {
        margin : -10px 0 24px 0;
        padding: 0 30px;
        height : 36px;

        .crop-select {
          width: 200px;

          .ant-select {
            width: 100%;

            .ant-select-selector {
              border             : none;
              // background-color: $tab-bg-grey;
              background-color   : white;
              border-radius      : 20px;

              .ant-select-selection-item {
                margin-top: 2px;
              }
            }
          }

          .ant-select-focused .ant-select-selector {
            box-shadow: 0px 0px 0px black;
          }
        }
      }

      .card-carousel {
        list-style    : none;
        padding       : 0;
        display       : flex;
        flex-direction: column;
        position      : relative;
      }

      .card {
        background   : $tab-bg-grey;
        border-radius: 30px;
        padding      : 20px;
        box-shadow   : 0 10px 5px rgba(0, 0, 0, 0.1);
        width        : 80vw;
        height       : 85vh;
        transition   : all 0.75s ease;
        opacity      : 0;
        position     : absolute;
        transform    : scale(0.85) translateY(50px);
      }

      .card h2,
      .card p {
        line-height: 1.5;
        margin     : 0;
      }

      .card.active {
        opacity   : 1;
        transform : scale(1) translateY(0);
        box-shadow: 0 30px 20px rgba(0, 0, 0, 0.2);
        z-index   : 1;
      }

      .card.next {
        opacity   : 1;
        z-index   : 0;
        margin-top: 10px;
      }

      .card.prev {
        transform : scale(1.1) translateY(-50px);
        z-index   : 2;
        opacity   : 0;
        visibility: hidden;
      }
    }

    // a:nth-child(2):visited ~ .line {
    //   // transform: translate(100px);
    //   transform: translateY(50px);
    // }
    // a:nth-child(3):visited ~ .line {
    //   transform: translateY(100px);
    // }
    // a:nth-child(4):visited ~ .line {
    //   transform: translateY(150px);
    // }
//   }
}