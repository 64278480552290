@import "../../constants/style-variables.scss";
.define-slots {
  padding: 10px 50px;
  background-color: $tab-container-grey;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  height: 75vh !important;
  .define-slots-title {
    color: $primary-font-blue;
    font-size: 40px;
    font-weight: 300;
    display: flex;
  }
  .add-slot-top-container {
    display: flex;
    justify-content: space-between;
    // width: 400px;
    padding: 0 10px 0px 10px;
    align-items: flex-end;
    height: 35px;
    .duration {
      font-size: 12px;
      color: silver;
    }
    .edit-button {
      color: $edit-blue;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 10px;
    }
  }
  .noteText {
    position: absolute;
    left: 10%;
  }
  .slots-container {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 310px 310px 309px 309px;
    overflow: auto;
    padding: 10px;
    max-height: 300px;
    // grid-template-rows: 50px 50px 50px;
  }

  .bottom-container {
    padding: 10px;
    // width: 400px;
    .update-time {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
    }
  }

  .ant-picker {
    border-radius: 10px;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: $input-border-grey;
    border-right-width: 1px !important;
  }
  .ant-picker-focused {
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
  }
  .ant-btn {
    border-radius: 24px !important;
    color: white !important;
    background: $primary-font-blue !important;
    border: none !important;
  }
}

.add-slot-modal {
  .ant-modal-content {
    border-radius: 15px !important;
  }
  .ant-btn {
    border-radius: 24px;
    color: white;
    background: $primary-font-blue;
    border: none;
    background-color: $primary-font-blue;
    &:focus,
    &:hover {
      background: $primary-font-blue;
      color: white;
    }
  }
  .add-slot-container {
    .add-slot-header {
      color: $primary-font-blue;
      font-size: 22px;
      margin-top: -10px;
      font-weight: 300;
    }
    .add-slot-content {
      .btn-container {
        margin-top: 14px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.slot-confirm-close-icon {
  top: 15px;
  position: absolute;
  right: 15px;
  color: #a7a7a7;
}
