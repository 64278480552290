.statutory-details-ant-spin {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: lightgrey;
  // opacity         : 0.2;
}

.statutory-details {
  height: 100%;

  // display: flex;
  // justify-content: center;
  // align-items: center;
  // color: red;
  // font-size: xx-large;
  // height: 100%;
  .form-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .login-btn {
      margin-top: 0px;
      // height          : 10px;
      margin-left: 5px;
      padding: 2px 8px !important;
      background-color: #20346a;
      width: 100px !important;
      height: 40px;
      // float: right;
      // align-items     : center;
      // align-self      : center;
      // position        : relative;
    }

    .row {
      margin-bottom: 5px;

      .remarks-text {
        background: #20346a;
        padding: 5px 10px;
        font-size: 13px;
        font-weight: 500;
        color: white;
        border-radius: 20px;
      }

      .dualColumnRow {
        .left {
          width: 200px;
          margin-left: 5px;
          margin-right: 5px;
          align-self: center;
        }

        .tds-section {
          margin-top: 10px;
          display: inline-block;
          position: absolute;
        }

        .right {
          .pan-image-name {
            border: 1px solid gray;
            width: 100%;
            padding: 6px;
            height: 40px;
            margin-right: 7px;
          }

          .pan-image-name-close {
            cursor: pointer;
            color: red;
            font-size: 16px;
            position: relative;
            border: 1px solid grey;
            border-radius: 60%;
            background-color: lightgrey;
            top: -10px;
            padding: 0px 3px;
            right: -15px;
          }
        }
      }
    }
    .ant-input {
      display      : inline-block;
      width        : 300px;
      line-height  : 1.8;
      color        : #20346A;
      font-weight  : 500;
      border-radius: 40px;
      margin-right : 5px;
      white-space  : pre-wrap;
    }
  }
}
