.bank-details-ant-spin {
  height             : 100%;
  display            : flex;
  justify-content    : center;
  align-items        : center;
  // background-color: lightgrey;
  // opacity         : 0.2;
}

.bank-details {
  height: 100%;

  .form-container {
    width     : 100%;
    height    : 100%;
    overflow-y: auto;

    .login-btn {
      margin-top            : 0px;
      // height          : 10px;
      margin-left           : 5px;
      padding               : 2px 8px !important;
      background-color      : #20346a;
      width                 : 100px !important;
      height                : 40px;
      // float              : right;
      // align-items     : center;
      // align-self      : center;
      // position        : relative;
    }

    .approve-btn {
      background-color: green !important;
      color           : white !important;
      float           : right;
      border          : 0px !important;
    }

    .reject-btn {
      background-color: red !important;
      color           : white !important;
      float           : right;
      border          : 0px !important;
    }

    .pushback-btn {
      background-color: grey !important;
      color           : white !important;
      float           : right;
      border          : 0px !important;
    }

    .row {
      margin-bottom: 5px;

      .remarks-text {
        background   : #20346a;
        padding      : 5px 10px;
        font-size    : 13px;
        font-weight  : 500;
        color        : white;
        border-radius: 20px;
      }

      .dualColumnRow {
        .left {
          width       : 170px;
          margin-left : 5px;
          margin-right: 5px;
          align-self  : center;
        }

        .right {
          .image-name {
            border      : 1px solid gray;
            width       : 100%;
            padding     : 6px;
            height      : 40px;
            margin-right: 7px;
          }

          .image-name-close {
            cursor          : pointer;
            color           : red;
            font-size       : 16px;
            position        : relative;
            border          : 1px solid grey;
            border-radius   : 60%;
            background-color: lightgrey;
            top             : -15px;
            padding         : 0px 3px;
            right           : -13px;
          }
        }
      }
    }

    .ant-input {
      display      : inline-block;
      width        : 300px;
      line-height  : 1.8;
      color        : #20346A;
      font-weight  : 500;
      border-radius: 40px;
      margin-right : 5px;
      white-space  : pre-wrap;
    }

    .ant-form {
      display   : block;
      width     : 100%;
      overflow-y: auto;
      height    : calc(100vh - 132px);
    }

    .ant-form-item {
      margin-bottom : 5px;
      padding       : 0%;
      display       : inline-flex;
      width         : 49%;
      margin-right  : 8px;
      border-spacing: 0ch;

      .widthset {
        width: 50%;
      }

      .ant-form-item-control {
        .widthset {
          width: 50%;
        }

        .dropdown {
          width: 50%;
        }

        .ant-checkbox-wrapper {
          margin-left: 0px !important;
        }

        .ant-input-content {
          width: 30%;
        }
      }
    }

    .content-text {
      font-size: 14px !important;
      display  : inline-block;
    }

    .ant-form-item-label {
      // white-space: normal !important;
      text-align   : left !important;
      // margin    : 5px auto;

      .widthset {
        width: 10%;
      }
    }

    .wrap-text {
      .ant-form-item-label {
        white-space: normal !important;
      }
    }

    .no-wrap-text {
      white-space: nowrap !important;

      // background-color: rosybrown;
      .small-font-size {
        font-size: 12px !important;
      }

      // .ant-form-item-label {
      //   white-space: nowrap !important;
      //   margin     : 7px 5px !important;
      // }
    }

    // .ant-input {
    //   line-height  : 2.2;
    //   width        : 100%;
    //   border-radius: 40px;
    // }
    .ant-input {
      display      : inline-block;
      width        : 300px;
      line-height  : 1.8;
      border-radius: 40px;
      margin-right : 5px;
      margin-block : auto;
      white-space  : pre-wrap;
    }

    .ant-select-selector {
      width        : 225px !important;
      height       : 30px !important;
      padding      : 5px 7px !important;
      border-radius: 40px !important;
    }

    .ant-input-lg {
      padding            : 6.5px 11px !important;
      font-size          : 16px !important;
      border             : 1px solid #999999;
      /* background-color: transparent !important; */
    }

    .internal-autofill-selected {
      appearance      : menulist-button;
      background-color: white !important;
    }

    .ant-input-affix-wrapper {
      border          : none;
      padding         : 0px;
      margin-top      : 20px;
      // border-bottom: 1px solid #e3e3ec;
    }

    .anticon-eye-invisible,
    .anticon-eye {
      height   : 20px;
      width    : 20px;
      font-size: 20px;
    }

    .ant-input-suffix {
      margin-left: 4px;
      /* display : none; */
      position   : absolute;
      right      : 15px;
      top        : 16px;
    }

    // .ant-btn-primary {
    //   border-radius: 20px;
    //   width: 139px !important;
    //   color: #fff;

    //   border-radius: 20px;
    //   height: 33px;
    //   font-size: 15px;
    //   line-height: 0px;
    // }

    .custom-link {
      color: #20346a;
    }

    .submit-btn {
      float           : right;
      margin          : 5px 0px;
      height          : 10px;
      color           : white;
      margin-left     : 5px;
      background-color: #20346a;
      width           : fit-content !important;
      height          : 40px;
    }
  }
}