@import "../../constants/style-variables.scss";
.copy-auction-bd {
  padding: 15px 30px;
  height: 88vh !important;
  .header {
    color: $primary-font-blue;
    font-size: 30px;
    font-weight: 300;
    display: flex;
    padding: 0 15%;
    .custom-select-container {
      height: 45px;
      border-bottom: 2px solid #fdbc2c;
      margin-right: 4px;
    }
    .custom-select-container .ant-select {
      font-size: 30px;
      font-weight: 400;
    }
  }
  .container {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    .table-container {
      width: 80%;
    }
    .terms-container {
      background-color: white;
      border-radius: 20px;
      width: 37%;
      padding: 10px 15px;
      height: calc(100vh - 152px);
      .terms-and-conditions {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      .terms {
        overflow: auto;
        height: 92%;
      }
    }
  }
}

.create-auction-link {
  color: $edit-blue;
  text-decoration: underline;
  cursor: pointer;
}

.create-auction-modal {
  .header {
    color: $primary-font-blue;
    font-size: 30px;
    font-weight: 300;
    display: flex;
    padding: 10 20px;
    .bold {
      font-weight: 400;
      padding: 0 5px;
    }
  }
  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-modal-body {
    padding: 24px 70px 24px 60px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }

  .modal-container {
    display: flex;
    justify-content: space-around;
    .form-items {
      display: flex;
      flex-direction: column;
      color: $form-label-grey;
      &.align-right {
        align-items: flex-end;
        width: 100px;
        gap: 6px;
        .label {
          padding: 0px;
        }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      .label {
        padding: 0 10px;
      }
      .label-saudha {
        padding: 13px 10px 0px 10px;
      }
      .noteText {
        padding-top: 12px;
        font-size: 13px;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 270px;
        border-radius: 20px;
      }
    }
    .left-container {
      width: 23%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .right-container {
      width: 70%;
      .slots-container {
        width: 110%;
        height: 330px;
        padding: 20px 15px 20px 5px;
        border-radius: 20px;
        box-shadow: 4px 4px 11px -8px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: 20px;
          width: 25vh;
        }
      }
    }
    .ant-input {
      border-radius: 15px;
    }
    .ant-picker {
      border-radius: 15px;
    }
  }
  .individual-slot-container .individual-slot {
    display: flex;
    gap: 10px;
  }
  .bottom-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-top: -60px;

    .remaining-time {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $form-label-grey;
    }
    .ant-btn {
      border-radius: 20px;
      color: white;
      background: #1a3062;
      border-color: #1a3062;
    }
  }
}
