.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-table {
  background: #ac585800 !important;
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 15px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    background-color: #f8f9fb;
  }
  .ant-table-container table > thead > tr:first-child th:nth-last-child(2) {
    border-top-right-radius: 15px;
  }
  .ant-table-cell-scrollbar {
    -webkit-box-shadow: 0 1px 0 1px #fafafa00;
    box-shadow: 0 1px 0 1px #fafafa00;
  }
  table {
    font-size: 12px;

    .ant-table-tbody > tr > td {
      background: white;
    }
    .ant-table-thead > tr:first-child > th {
      color: #ffffffd9;
      background: #030b30;
      border-bottom: none;
    }
    .ant-table-thead > tr:last-child > th {
      color: #ffffffb2;
      background: #0b123c;
      border-bottom: 1px solid #f0f0f0;
      padding: 4px 8px;
      font-weight: 400;
      font-size: 11px;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 6px 8px;
      vertical-align: baseline;
    }
  }
}

/* ...................ScrollBar..................... */

/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  border-radius: 10px;
  width: 8px;
  height: 8px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
  opacity: 0.7;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c2c0c0;
  opacity: 0.5;
}

/* ...................Spin..................... */

.ant-spin-dot {
  font-size: 35px !important;
  .ant-spin-dot-item {
    width: 15px !important;
    height: 15px !important;
    background-color: #ffc107 !important;
  }
}

/* ...................MessagePopUp..................... */
.ant-message-notice-content {
  border-radius: 30px !important;
}

/* ...................Modal..................... */
.ant-modal-content {
  border-radius: 20px !important;
}

.ant-btn {
  border-radius: 20px !important;
}

/* ...................DisabledElements..................... */

.ant-btn-primary[disabled] {
  color: #ffffff9e !important;
}
