@import "../../constants/style-variables.scss";

.archives {
  align-items: center;
  // .download-button-container {
  //   display: flex;
  //   justify-content: flex-end;
  //   margin-right: 20px;
  //   .download-btn {
  //     // background-color: #154478;
  //     bottom: 3% !important;
  //     position: absolute;
  //     height: 40px;
  //     font-size: 13px;
  //   }
  // }
  .filter-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 15px;

    .refresh {
      position: fixed;
      left: 50px;
      top: 110px;
      width: max-content;
      color: $primary-font-blue;
      border-color: $primary-font-blue;
      padding: 0 10px;
    }

    // .ant-btn > .anticon {
    //   line-height: 0;
    // }

    .selected-wheat {
      background-color: white;
      border-radius: 10px;
      width: 120px;
      padding: 3px 10px;
      box-shadow: 0px 2px 5px -3px;

      .selected-wheat-label {
        color: #bdbcbc;
        font-size: 10px;
        font-weight: 500;
      }
    }
  }

  .auction-report-table-container {
    padding: 0 15px 15px 15px;
    width: 100%;

    .download-button-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .download-btn-styles {
        background-color: #154478;
        font-size: 13px;
      }
    }
  }

  .sauda-status {
    color: $orange;
    &.red {
      color: $red;
    }
    &.green {
      color: $green;
    }
  }

  .status {
    color: $form-label-grey;
    &.red {
      color: $red;
    }
    &.green {
      color: $green;
    }
    &.orange {
      color: $orange;
    }
  }
}
.ant-table-column-sorters {
  padding: 0 !important;
}
