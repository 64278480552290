@import "../../constants/style-variables.scss";

.basic-details-ant-spin {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: lightgrey;
  // opacity         : 0.2;
}

.basic-details {
  height: 100%;

  .ant-btn .btn-container {
    margin-top: -25px;
    margin-left: 5px;
    padding: 2px 8px !important;
    background-color: #20346a;
    width: fit-content !important;
    float: right;
    color: white !important;
  }

  .form-container {
    width: 100%;
    height: 100%;
    // display: flex;
    overflow-y: auto;
    // cursor        : not-allowed !important;
    // pointer-events: none !important;

    .login-btn {
      margin-top: 0px;
      // height          : 10px;
      margin-left: 5px;
      padding: 2px 8px !important;
      background-color: #20346a;
      width: 100px !important;
      height: 40px;
      float: right;
      // align-items     : center;
      // align-self      : center;
      // position        : relative;
    }

    .row {
      margin-bottom: 5px;

      .remarks-text {
        background: #20346a;
        padding: 5px 10px;
        font-size: 13px;
        font-weight: 500;
        color: white;
        border-radius: 20px;
      }

      .dualColumnRow-existing {
        margin-top: 20px;

        .left {
          width: 200px;
          margin-left: 5px;
          margin-right: 5px;
          align-self: center;
        }

        .right {
        }

        .vendor-width {
          margin-left: 10px;

          .ant-input {
            display: inline-block;
            width: 195px;
            line-height: 1.8;
            border-radius: 40px;
            margin-right: 5px;
            margin-block: auto;
            white-space: pre-wrap;
          }
        }
      }

      .dualColumnRow {
        .left {
          width: 200px;
          margin-left: 5px;
          margin-right: 5px;
          align-self: center;
        }

        .right {
        }

        .vendor-width {
          margin-left: 10px;

          .ant-input {
            display: inline-block;
            width: 195px;
            line-height: 1.8;
            border-radius: 40px;
            margin-right: 5px;
            margin-block: auto;
            white-space: pre-wrap;
          }
        }
      }
    }

    .ant-input {
      display: inline-block;
      width: 300px;
      line-height: 1.8;
      color: #20346a;
      font-weight: 500;
      border-radius: 40px;
      margin-right: 5px;
      white-space: pre-wrap;
    }
  }
}
.grey-color {
  background: #f5f5f5;
}
