@import "../../constants//style-variables.scss";

.mobile_home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 75px);

  .mobile_preference {
    width: 600px;
    background-color: white;
    border-radius: 18px;
    padding: 13px 20px;
    color: $form-label-grey;
    box-shadow: 4px 4px 10px -5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .row {
      margin-bottom: 5px;

      .dualColumnRow {
        .left {
          width: 200px;
          margin-left: 5px;
          margin-right: 5px;
          align-self: center;
        }
      }
    }

    .login-btn {
      margin-top: 0px;
      margin-left: 5px;
      padding: 2px 8px !important;
      background-color: #20346a;
      // width: fit-content !important;
      height: 40px;
      float: right;
      width: 100px;
      // align-items     : center;
      // align-self      : center;
      // position        : relative;
    }

    .ant-input {
      display: inline-block;
      width: 300px;
      line-height: 1.8;
      border-radius: 40px;
      margin-right: 5px;
      margin-block: auto;
      white-space: pre-wrap;
    }
  }
}
