@import "../../constants/style-variables.scss";

.saudha {
  background-color: $background-grey;
  height: 100vh;

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: black !important;
  }

  .ant-input[disabled] {
    color: black !important;
  }

  .ant-checkbox-disabled .ant-checkbox-input {
    color: black !important;
  }

  .header {
    color: $primary-font-blue;
    height: 45px;
    background-color: white;
    width: 100%;
    display: flex;
    gap: 10px;
    font-size: 16px;
    padding: 10px 17px;
    box-shadow: 0px 1px 14px -8px;
    .back-btn {
      font-size: 24px;
      margin-top: -8px;
      font-weight: 900;
      cursor: pointer;
    }
    .title {
      font-weight: 700;
    }
  }
  .content-container {
    padding: 20px;
    .top-container {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;
      height: 75vh;
      .left-container {
        .top-display {
          display: flex;
          justify-content: center;
          gap: 20px;
        }
        .content-display {
          display: flex;
          justify-content: space-around;
          .table-container {
            margin: 10px 10px;
            width: 70%;
            .accept-reject {
              display: flex;
              justify-content: space-between;
              margin: 10px 20px;
              .refresh {
                border-radius: 30px;
              }
              .visible {
                display: visible;
              }
              .buttons {
                display: flex;
                gap: 10px;
                &.disabled {
                  opacity: 0.5;
                  pointer-events: none;
                  // display: none;
                }
                .red {
                  border: none;
                  border-radius: 30px;
                  background-color: $red;
                  height: 30px;
                  color: white;
                }
                .green {
                  height: 30px;
                  border: none;
                  border-radius: 30px;
                  color: white;
                  background-color: $green;
                }
                .waiting {
                  height: 30px;
                  border: none;
                  border-radius: 30px;
                  color: white;
                  background-color: #ee893d;
                }
              }
            }
            .accepted-rejected {
              display: flex;
              justify-content: flex-end;
              margin: 10px 20px;
              .refresh {
                border-radius: 30px;
              }
              .buttons {
                display: flex;
                gap: 10px;
                &.disabled {
                  opacity: 0.5;
                  pointer-events: none;
                }
                .red {
                  border: none;
                  border-radius: 30px;
                  background-color: $red;
                  height: 30px;
                  width: 65px;
                  padding: 5px 7px;
                  color: white;
                }
                .green {
                  height: 30px;
                  width: 70px;
                  padding: 5px;
                  border: none;
                  border-radius: 30px;
                  color: white;
                  background-color: $green;
                }
                .notProcessed {
                  height: 30px;
                  width: 102px;
                  padding: 5px;
                  border: none;
                  border-radius: 30px;
                  color: white;
                  background-color: #ee893d;
                }
                .display-none {
                  display: none;
                }
              }
            }
            .move-up {
              margin-top: -42px;
            }
            .move-down {
              margin-top: 0px;
            }
            .disabled {
              display: none;
            }
            .supplier-table {
              // height: 57vh;
              border-radius: 5px;
              // overflow-y: auto;
              // .ant-table-header{
              //   overflow-y: hidden;
              // }
              // .ant-table-tbody{
              //   overflow-y: auto;
              // }
              .ant-table table .ant-table-thead > tr:last-child > th {
                padding: 9px 8px;
                font-size: 13px;
                word-break: keep-all;
                font-weight: 600;
                overflow-y: hidden;
              }
              .ant-table table .ant-table-tbody > tr > td {
                background: transparent;
                font-size: 15px;
                font-weight: 450;
              }
              tr:last-child > th:last-child {
                background-color: transparent;
                display: none;
              }
            }
            .supplier-table-color {
              .ant-table table .ant-table-tbody > tr > td {
                background: white;
              }
            }
          }
          .details-container {
            width: 45%;
            margin: 55px 2px;
            padding: 10px 0px;
            font-size: 22px;
            color: $primary-font-blue;
            .details {
              width: 100%;
              box-shadow: -2px -2px 7px rgba(255, 255, 255, 1),
                2px 2px 7px rgba(0, 0, 0, 0.1);
              padding: 10px 20px;
              border-radius: 20px;
            }
            .rebate {
              font-weight: 300;
              padding: 10px 20px;
              font-size: 18px;
            }
            .items {
              display: flex;
              justify-content: space-between;
              .label {
                font-weight: 300;
                font-size: 18px;
              }
              .value {
                display: flex;
                font-weight: 700;
                gap: 5px;
                font-size: 18px;
                .unit {
                  font-size: 16px;
                  font-weight: 500;
                  margin-top: 4px;
                }
                &.green {
                  color: $green;
                }
              }
            }
          }
        }
      }
      .right-container {
        width: 100%;
        padding: 1px;
        background-color: white;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: max-content;
        .ant-tabs {
          .ant-tabs-nav::before {
            border-bottom: none;
          }
          .ant-tabs-tab {
            font-size: 12px;
            margin: 0px;
            padding: 11px 10px;
            color: $primary-font-blue !important;
          }
          .ant-tabs-tab:hover,
          .ant-tabs-tab:focus,
          .ant-tabs-tab:active {
            color: $primary-font-blue !important;
          }
          .ant-tabs-nav {
            margin: 0 0 7px 0;
            .ant-tabs-ink-bar {
              display: none;
            }
            .ant-tabs-nav-wrap {
              // background-color: $primary-font-blue !important;
              line-height: 6px;
              border-radius: 20px;
              border: 1px solid $primary-font-blue;
            }
          }
          .ant-tabs-tab-active {
            background-color: $primary-font-blue;
            .ant-tabs-tab-btn {
              color: white;
              font-weight: 400;
            }
          }
        }
        .hub-comparison {
          .title {
            font-size: 19px;
            font-weight: 300;
            color: $primary-font-blue;
          }
          .add-hub {
            display: flex;
            justify-content: flex-start;
            padding: 0 20px;
            margin: 3px 0;
            .ant-btn {
              border-radius: 15px;
              font-size: 11px;
              height: 27px;
              border-color: $primary-font-blue;
              color: $primary-font-blue;
            }
          }
          .content {
            display: flex;
            flex-direction: column;
            gap: 7px;
            overflow: auto;
            height: 62vh;
            .individual-content {
              padding: 10px 15px;
              background-color: $primary-font-blue;
              border-radius: 25px;
              min-width: 200px;
              color: #e0ecff;
              font-size: 12px;
              margin: 0px 5px;
              .red {
                color: $red;
              }
              .green {
                color: $green;
              }

              .line {
                height: 1px;
                &.red {
                  background-color: $red;
                }
                &.green {
                  background-color: $green;
                }
              }

              .data {
                display: flex;
                justify-content: space-between;
                font-size: 10px;
                // .range {
                // }
                .price {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                }
              }
            }
          }
        }
        .bid-comparison {
          .content {
            display: flex;
            flex-direction: column;
            gap: 7px;
            overflow: auto;
            height: 62vh;
            overflow-y: auto;
            .individual-content {
              padding: 10px 15px;
              background-color: $primary-font-blue;
              border-radius: 25px;
              min-width: 200px;
              color: #e0ecff;
              font-size: 12px;
              margin: 0px 5px;
              .green {
                color: $green;
              }
              .auction-date {
                display: flex;
                justify-content: space-between;
              }
              .line {
                height: 1px;
                background-color: #e0ecff;
              }

              .data {
                display: flex;
                flex-direction: column;
                font-size: 10px;

                .data-item {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                }
              }
            }
          }
        }
      }
    }
    .bottom-container {
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      width: 97%;
      padding: 20px 10px;
      pointer-events: none;
      .content {
        width: 49%;
        height: 60px;
        background-color: white;
        border-radius: 20px;
        padding: 10px 20px;
        font-weight: 500;
        color: $primary-font-blue;
        &.right {
          .ant-progress-success-bg,
          .ant-progress-bg {
            background-color: $green;
          }
        }
        .head {
          display: flex;
          justify-content: space-between;
        }
        .ant-slider-rail {
          height: 8px;
          border-radius: 16px;
        }
        .ant-slider-track {
          height: 8px;
          background-color: $green;
          border-radius: 8px;
        }
        .ant-slider-handle {
          display: none;
        }
        .ant-slider:hover .ant-slider-rail {
          background-color: #f5f5f5;
        }
        .ant-slider {
          margin: 3px 6px 10px;
        }
      }
    }
  }
  .display-item {
    padding: 8px 10px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    display: flex;
    min-width: 165px;
    height: 54px;
    background: white;
    border-radius: 15px;
    color: $primary-font-blue;
    box-shadow: 1px 3px 10px -6px;
    .icon {
      width: 34px;
      height: 39px;
      fill: $primary-font-blue;
    }
    .title-container {
      font-weight: 600;
      line-height: 18px;
      .title {
        font-size: 11px;
        font-weight: 400;
      }
      .subtitle {
        font-size: 17px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.add-hub-modal {
  .ant-btn {
    border-radius: 15px;
    font-size: 14px;
    height: 30px;
    border-color: $primary-font-blue !important;
    color: $primary-font-blue !important;
    color: white !important;
    background-color: $primary-font-blue !important;
    // width: 100px;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .modal-container {
    color: $form-label-grey;
    .btn-container {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
    }
    .ant-select {
      width: 100%;
    }
  }
  .add-hub-head {
    color: $primary-font-blue;
    font-size: 22px;
    font-weight: 300;
  }
}

.rebate-info-sauda {
  display: flex;
  justify-content: space-between;

  .info {
    width: 30px;
    height: 30px;
    background-color: #1a3062;
    border-radius: 10px;
    color: white;
    padding: 0px 9px;
    cursor: pointer;
    margin: 5px;
    pointer-events: all !important;
  }
}
.rebate-modal {
  // .ant-modal-content {
  //   background-color: $tab-bg-grey;
  // }
  .ant-modal-body {
    margin-top: -64px;
  }
  .rebate-container {
    width: 100%;
    padding: 10px 0px;
    .ant-table table .ant-table-tbody > tr > td {
      padding: 1px 8px;
    }
    .ant-table-tbody tr td {
      border-bottom: 1px solid #959595;
      border-left: 1px solid #959595;
    }
    .ant-table table .ant-table-thead > tr:last-child > th {
      border-bottom: none;
    }
    .ant-table-tbody tr td:last-child {
      border-right: 1px solid #959595;
    }
    // .ant-table-tbody td[rowspan] {
    //   // border-top: 1px solid rgb(73, 73, 73);
    // }
  }
  .rebate-header {
    color: $primary-font-blue;
    font-size: 22px;
    margin-top: -10px;
    font-weight: 300;
  }
}

.table-row-dark {
  background-color: #959595;
}
.table-row-light {
  background-color: #ffffff;
}
