@import "../../constants//style-variables.scss";
.intemediate-sauda-landing {
  height: 88vh !important;

  .ant-tabs-content-holder {
    background-color: $tab-container-grey;
    height: 85vh;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
    background-color: $tab-bg-grey;
  }
  .ant-tabs-tab {
    margin: 0px 0px;
    padding: 8px 0px;
    svg {
      fill: $tab-icon-inactive-blue;
    }
    .ant-tabs-tab-btn {
      padding: 0 15px;
      color: $tab-icon-inactive-grey;
      font-size: 13px;
    }
    .ant-tabs-tab:hover {
      color: $tab-icon-inactive-grey;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: $tab-container-grey;
    svg {
      fill: $tab-icon-active-blue;
    }
    .ant-tabs-tab-btn {
      color: $primary-font-blue;
      font-weight: 500;
    }
  }

  .tabname-container {
    display: flex;
    .tab-name {
      margin: 2px 0 0 0;
    }
    .tab-img {
      width: 23px;
      height: 23px;
      margin: 0px 5px 0 0;
    }
  }
}
