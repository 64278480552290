@import "../../constants/style-variables.scss";

.custom-select-container {
  // width: 150px;
  height       : 60px;
  border-bottom: 3px solid $mustard-yellow;
  margin-right : 4px;

  .ant-select {
    width         : 100%;
    // font-weight: 500;
    font-size     : 40px;

    .ant-select-arrow {
      font-size: 20px;

      svg {
        fill: $primary-font-blue;
      }
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width  : 100% !important;
    height : 37px !important;
    padding: 0 11px !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border          : none;
    background-color: transparent;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: $primary-font-blue !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0);
  }
}

.select-date-container {
  width: 220px;

  .ant-picker {
    height          : 32px;
    border          : none;
    background-color: transparent;

    .ant-picker-input>input::placeholder {
      color: $primary-font-blue;
    }

    svg {
      fill: $primary-font-blue;
    }
  }

  .ant-picker-focused {
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
    box-shadow        : 0 0 0 2px rgba(24, 144, 255, 0);
  }
}