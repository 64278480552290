@import "../../constants/style-variables.scss";

.system-params {
  display: flex;
  justify-content: center;
  padding: 10px 65px 0 5px;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  height: 81vh !important;

  .set-height {
    height: 50px !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: black !important;
  }

  .ant-picker-input > input[disabled],
  .ant-input[disabled] {
    color: black !important;
  }

  .ant-checkbox-disabled .ant-checkbox-input {
    color: black !important;
  }

  .header {
    color: $primary-font-blue;
    font-size: 25px;
    font-weight: 300;
    display: flex;

    .custom-select-container .ant-select {
      font-size: 35px;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.75;
    cursor: not-allowed;
  }

  .left-container {
    width: 470px;
  }

  .right-container {
    width: 550px;
    margin-left: 40px;

    .discount-item {
      display: flex;
      margin: 4px;
      width: 60%;

      div {
        width: 130px;
        padding-top: 5px;
      }
    }
  }

  .outer-container {
    padding: 20px 20px 50px 20px;
    margin: 10px 30px;
    background-color: white;
    border-radius: 25px;
    box-shadow: 2px 2px 7px -5px black;
    width: 100%;
    color: $form-label-grey;
    max-height: 80vh;
    overflow-y: auto;
  }

  .form-container {
    .price-tick-size {
      border-radius: 17px;
      border: 1px solid #d9d9d9;
      padding: 4px 11px;
    }

    .radio-and-btn {
      display: flex;
      justify-content: space-between;

      .ant-btn {
        border-radius: 100px;
        background: $mustard-yellow;
        border: none;
        color: #fff;
      }

      .ant-btn:hover,
      .ant-btn:focus {
        color: #fff;
        background: $mustard-yellow;
      }
    }

    .ant-input {
      border-radius: 17px;
    }

    .ant-picker {
      border-radius: 17px;
      width: 100%;

      .ant-picker-ok {
        .ant-btn {
          border-radius: 2px !important;
        }
      }
    }

    .ant-picker:hover,
    .ant-picker:focus,
    .ant-picker-focused,
    .ant-input:focus,
    .ant-input:hover {
      border-color: $input-border-grey;
      -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
    }

    .update-btn {
      // position: absolute;
      margin: 8px 0 0 30px;

      .ant-btn-primary {
        border-radius: 100px;
        background: $primary-font-blue;
        border: none;
      }

      .ant-btn-primary:hover,
      .ant-btn-primary:focus {
        color: #fff;
        background: $primary-font-blue;
      }
    }

    .ant-select-selector {
      border-radius: 17px !important;
      cursor: pointer !important;
    }
  }
}
