@import "../../constants/style-variables.scss";
.home {
  padding: 20px 35px;
  height: 88vh !important;
  .header {
    font-size: 35px;
    font-weight: 300;
    color: $primary-font-blue;
  }
  .contents {
    padding: 10px 30px;
    .preference {
      width: 400px;
      background-color: white;
      border-radius: 18px;
      padding: 13px 20px;
      color: $form-label-grey;
      box-shadow: 4px 4px 10px -5px;
      .select-container {
        width: 135px;
        margin: 10px 0px;
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
        }
        .ant-select {
          width: 100%;
          color: $primary-font-blue;
          .ant-select-selector {
            border-radius: 50px;
            border-color: $primary-font-blue;
          }
          .ant-select-arrow {
            color: $primary-font-blue;
          }
        }
      }
      .ant-checkbox-wrapper {
        color: $primary-font-blue;
        .ant-checkbox {
          .ant-checkbox-inner {
            border-color: $primary-font-blue;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            border-color: $primary-font-blue;
            background-color: $primary-font-blue;
          }
        }
      }
    }
  }
}
