@import "../../constants/style-variables.scss";
.single-slot-container {
  background-color: white;
  height: 82px;
  width: 300px;
  padding: 18px;
  border-radius: 25px;
  box-shadow: 2px 2px 7px -5px black;
  margin: 10px 0 0 0;
  zoom: 1;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  &.disabled {
    pointer-events: none;
    // box-shadow: 0px 0px 0px 0px black;
    // height: 95px;
    // width: 400x;
    transition: all 0.3s ease-in-out;
  }
  &.edited {
    border: 1px solid #1890ff;
    &.border-red {
      border-color: $red;
    }
  }
  .temp-div {
    display: flex;
    justify-content: flex-end;
    margin: 1px 0px 0px 171px;
    color: #ffffff;
    font-size: 9px;
    background: #fdbc2c;
    padding: 0 9px;
    border-radius: 10px;
  }
  .active-switch {
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: relative;
    margin: -9px 0 -14px 225px;
    transition: all 0.1s ease-in-out;
    opacity: 1;
    &.disable {
      // height: 0px;
      // width: 0px;

      margin: -23px 0 0 392px;
      opacity: 0;
      transition: all 0.1s ease-in-out;
      //   transform: translateX(0);
    }
  }
  // .ant-switch-checked {
  //   background-color: #1a3062;
  // }
  .header {
    color: $filter-head-grey;
  }
  .ant-picker-input > input::placeholder {
    // color: rgb(46, 46, 46);
  }
  .ant-picker svg {
    fill: rgb(46, 46, 46);
  }
  .ant-picker-range {
    width: 100%;
    border-radius: 9px;
  }
  .ant-picker-focused {
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: $input-border-grey;
    border-right-width: 1px !important;
  }

  .ant-switch {
    min-width: 37px;
    height: 100%;
    &.ant-switch-checked .ant-switch-handle {
      left: calc(100% - 14px - 2px);
    }
    .ant-switch-handle {
      width: 13px;
      height: 13px;
    }
  }
}
