@import "../../constants//style-variables.scss";

.landingPage-itc-logo {
  width: 180px;
}

.landing-page {
  // height: 100vh;
  // .ant-layout-footer {
  //   color: rgba(0, 0, 0, 0.85) !important;
  //   font-size: 14px !important;
  //   background: #ebebf5 !important;
  //   // margin: -3px auto !important;
  //   text-align: center !important;
  //   // position  : absolute;
  //   // bottom : 0;
  //   // height: 20px !important;
  //   // width: 100% !important;
  //   // z-index: 9999 !important;
  //   // padding: 0px !important;
  //   // padding-top: 4px !important;
  // }

  .ant-tabs-tab {
    margin: 0px 0px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;

    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    .ant-tabs-tab-btn {
      padding: 0 15px;
      color: $tab-btn-grey;
      font-size: 13px;
    }

    .ant-tabs-tab:hover {
      color: $tab-btn-grey;
    }
  }

  .ant-tabs-ink-bar {
    background: $mustard-yellow !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: $primary-font-blue;
      font-weight: 800;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0px !important;
    background-color: white;
  }
}

.sample-logo {
  font-size: 28px;
  padding: 0 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 500;
  color: #ffc107;
  text-decoration: line-through;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.logout-button {
  padding: 0 15px;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.avatar-container {
  padding: 0 20px;
  cursor: pointer;
}

.avatar-popover {
  .ant-popover-inner {
    border-radius: 16px !important;
  }
}
