//Colors

//common colors
$mustard-yellow: #fdbc2c;
$primary-font-blue: #1a3062;
$background-grey: #e9f0fa;

//tab button colors
$tab-btn-grey: #cacfd2;

//sys-params colors
$form-label-grey: #969696;
$input-border-grey: #d9d9d9;

//view-auction-landing colors
$tab-bg-grey: #e9f0fa;
$tab-container-grey: #f8f9fb;
$tab-icon-active-blue: #5079be;
$tab-icon-inactive-blue: #94bbff;
$tab-icon-inactive-grey: #838688;

//Define slot color
$edit-blue: #40a9ff;

//custom filter
$filter-head-grey: #bdbcbc;
$filter-blue: #1d2c46;

$red: #fd423d;
$green: #52ff72;
$orange: #ee893d;
