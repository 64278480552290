@import "../../constants/style-variables.scss";

.individual-slot-container {
  .individual-slot {
    display: flex;
    // justify-content: center;
    gap: 15px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 20px;
      width: 30vh;
    }
    .slot {
      width: 70px;
    }
    .purchased-qty {
      width: 91px;
      text-align: end;
    }
    .max-price {
      width: 100px;
    }
    textarea.ant-input {
      height: 77px;
      min-height: 77px;
    }
  }
  .time-display {
    color: $filter-head-grey;
    font-size: 12px;
    padding: 0 6px;
  }
  .error-qty {
    color: $red;
    font-size: 12px;
    padding: 0 6px;
  }
}

.form-items {
  display: flex;
  flex-direction: column;
  color: $form-label-grey;
  &.align-right {
    align-items: flex-end;
    width: 100px;
    gap: 6px;
    .label {
      padding: 0px;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .label {
    padding: 0 10px;
    &.help {
      display: flex;
      justify-content: space-between;
      .info {
        width: 20px;
        height: 20px;
        background-color: #1a3062;
        border-radius: 10px;
        color: white;
        padding-left: 6px;
        cursor: pointer;
        pointer-events: all !important;
      }
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 20px;
  }
  .ant-input {
    border-radius: 15px;
  }
  .ant-picker {
    border-radius: 15px;
  }
}

.rebate-modal {
  // .ant-modal-content {
  //   background-color: $tab-bg-grey;
  // }
  .ant-modal-body {
    margin-top: -64px;
  }
  .rebate-container {
    width: 100%;
    padding: 10px 0px;
    .ant-table table .ant-table-tbody > tr > td {
      padding: 1px 8px;
    }
    .ant-table-tbody tr td {
      border-bottom: 1px solid #959595;
      border-left: 1px solid #959595;
    }
    .ant-table table .ant-table-thead > tr:last-child > th {
      border-bottom: none;
    }
    .ant-table-tbody tr td:last-child {
      border-right: 1px solid #959595;
    }
    .ant-table-tbody td[rowspan] {
      // border-top: 1px solid rgb(73, 73, 73);
    }
  }
  .rebate-header {
    color: $primary-font-blue;
    font-size: 22px;
    margin-top: -10px;
    font-weight: 300;
  }
}
