@import "../../constants/style-variables.scss";
.popover-container {
  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    .userName {
      font-weight: 500;
    }
    .role {
      font-size: 12px;
      color: $tab-icon-inactive-grey;
    }
  }
  .bottom-container {
    margin: 10px 0;
    display: flex;
    gap: 10px;
    .anticon {
      padding: 7px 3px;
    }
    .change-pw-container {
      display: flex;
      width: 160px;
      padding: 0 10px;

      line-height: 30px;
      cursor: pointer;
      border: 1px solid $form-label-grey;
      color: $form-label-grey;
      border-radius: 20px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                              supported by Chrome, Edge, Opera and Firefox */
      &:hover {
        border: 1px solid $primary-font-blue;
        color: $primary-font-blue;
      }
    }
    .logout-button {
      display: flex;
      width: 81px;
      line-height: 30px;
      padding: 0 10px;
      cursor: pointer;
      border: 1px solid $form-label-grey;
      color: $form-label-grey;
      border-radius: 20px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
      &:hover {
        border: 1px solid $primary-font-blue;
        color: $primary-font-blue;
      }
    }
  }
}
