@import "../../constants/style-variables.scss";
.userManagement {
  align-items: center;

  .user-table-container {
    padding: 0 15px 15px 15px;

    .ant-table table .ant-table-thead > tr:last-child > th {
      padding: 9px 8px;
      font-size: 12px;
      height: 40px;
      color: white;
      border-right: 4px solid #e8e8e8;
    }

    .ant-table table .ant-table-tbody > tr > td {
      border-right: 4px solid #e8e8e8;
    }
  }
  //   .download-button-container {
  //     display: flex;
  //     justify-content: flex-end;
  //     margin-right: 20px;
  //     .download-btn {
  //       background-color: #154478;
  //       bottom: 3% !important;
  //       position: absolute;
  //       height: 40px;
  //       font-size: 13px;
  //     }
  //
  .filter-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 15px;
    .refresh {
      position: fixed;
      left: 50px;
      top: 110px;
      width: max-content;
      color: $primary-font-blue;
      border-color: $primary-font-blue;
      padding: 0 10px;
    }
    .ant-btn > .anticon {
      line-height: 0;
    }
  }

  .sauda-status {
    color: $orange;
    &.red {
      color: $red;
    }
    &.green {
      color: $green;
    }
  }
  .status {
    color: $form-label-grey;
    &.red {
      color: $red;
    }
    &.green {
      color: $green;
    }
    &.orange {
      color: $orange;
    }
  }
}
