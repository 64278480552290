@import "../../constants/style-variables.scss";

.upcoming-auction {
  align-items: center;
  display: flex;
  flex-direction: column;

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: black !important;
  }

  .ant-input[disabled] {
    color: black !important;
  }

  .ant-checkbox-disabled .ant-checkbox-input {
    color: black !important;
  }

  // .download-button-container {
  //   display: flex;
  //   justify-content: flex-end;
  //   width: 100%;
  //   .download-btn {
  //     margin-right: -15px;
  //     background-color: #154478;
  //     bottom: 3% !important;
  //     right: 10% !important;
  //     position: absolute;
  //     height: 40px;
  //     font-size: 13px;
  //   }
  // }

  .disable-field {
    /* display: none; */
    pointer-events: none !important;
    cursor: not-allowed !important;
  }

  .filter-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding: 15px;

    .refresh {
      position: fixed;
      left: 50px;
      top: 110px;
      width: max-content;
      color: $primary-font-blue;
      border-color: $primary-font-blue;
      padding: 0 10px;
    }

    .ant-btn > .anticon {
      line-height: 0 !important;
    }

    .selected-wheat {
      background-color: white;
      border-radius: 10px;
      width: 120px;
      padding: 3px 10px;
      box-shadow: 0px 2px 5px -3px;

      .selected-wheat-label {
        color: #bdbcbc;
        font-size: 10px;
        font-weight: 500;

        .selected-wheat-value {
        }
      }
    }
  }

  .time-data-container {
    padding: 0 37px 0 30px;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: 300;
    margin-top: -15px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  .upcoming-table-container {
    padding: 0 15px 15px 15px;

    // width: 1150px;
    width: 96%;

    .ant-table-body {
      color: #000000 !important;
      // max-height: 577px !important;
    }

    .ant-table-tbody {
      font-size: 13px;
    }

    .disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.75;
    }

    .select-year-disabled {
      color: black;
      width: 100%;
    }
  }

  .edit-container {
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid $orange;
    color: $orange;
    padding: 0 5px;
    width: 55px;
    text-align: center;

    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.ant-popover .ant-popover-placement-top .competitor-hover-container {
  background-color: greenyellow;

  .ant-popover-content {
    .ant-popover-inner {
      .ant-popover-title {
        width: 50 !important;
      }
    }
  }
}

.claim-container {
  width: 16px;
}

.edit-modal {
  .header {
    color: $primary-font-blue;
    font-size: 22px;
    margin-top: -10px;
    font-weight: 300;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .label.help {
      justify-content: flex-start;
      gap: 10px;
    }
  }

  .update-btn-container {
    margin: 10px 0 0 0;
    display: flex;
    justify-content: flex-end;

    .ant-btn {
      background-color: $primary-font-blue;
      color: white;
      border-color: $primary-font-blue;
    }
  }
}

.rebate-info-upcoming {
  display: flex;
  justify-content: space-between;

  .info {
    width: 20px;
    height: 20px;
    background-color: #1a3062;
    border-radius: 10px;
    color: white;
    padding: 2px 7px;
    cursor: pointer;
    pointer-events: all !important;
  }
}

.rebate-modal {
  // .ant-modal-content {
  //   background-color: $tab-bg-grey;
  // }
  .ant-modal-body {
    margin-top: -64px;
  }

  .rebate-container {
    width: 100%;
    padding: 10px 0px;

    .ant-table table .ant-table-tbody > tr > td {
      padding: 1px 8px;
    }

    .ant-table-tbody tr td {
      border-bottom: 1px solid #959595;
      border-left: 1px solid #959595;
    }

    .ant-table table .ant-table-thead > tr:last-child > th {
      border-bottom: none;
    }

    .ant-table-tbody tr td:last-child {
      border-right: 1px solid #959595;
    }

    .ant-table-tbody td[rowspan] {
      // border-top: 1px solid rgb(73, 73, 73);
    }
  }

  .rebate-header {
    color: $primary-font-blue;
    font-size: 22px;
    margin-top: -10px;
    font-weight: 300;
  }
}

.submit-btn {
  margin-right: 0px;
  // &:hover {
  //   background-color: green !important;
  // }
}

.action-button img {
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;

  .ant-popover-title {
    // min-width: 177px;
    // min-height: 32px;
    // margin: 0;
    // padding: 5px 16px 4px;
    color: white;
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    background: black;
    opacity: 0.7;
  }

  .ant-popover-inner-content {
    // padding: 6px 6px;
    background-color: transparent;
    padding: 0;
    /* background   : black;
  opacity           : 0.5; */
    // border-radius: 3px;
  }
}

.action-submitted-button {
  height: 25px !important;
  width: 25px !important;
  display: block !important;
}

.conditions-header {
  color: $primary-font-blue;
  font-size: 22px;
  margin-top: -10px;
  font-weight: 300;
}

.hub-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .form-top-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .row {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .left {
        font-size: 12px;
        font-weight: 600;
        margin-top: 6px;
        width: 32%;
      }

      .other-right {
        display: flex;
        border-bottom: 2px solid #999999;
        width: 45% !important;

        .ant-input {
          font-weight: 800;
          padding: 0px 5px;
          color: black;
          background-color: #fff0;
          border: none;
        }

        .unit {
          white-space: nowrap;
        }
      }

      .right,
      .middle {
        display: flex;
        border-bottom: 2px solid #999999;
        width: 30% !important;

        .ant-input {
          font-weight: 800;
          padding: 0px 5px;
          color: black;
          background-color: #fff0;
          border: none;
        }

        .unit {
          margin-top: 20px;
          white-space: nowrap;
          font-size: 11px;
        }
      }
    }
  }

  .form-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin: 24px 0 0 0;

    // padding: 0 30px;
    .ant-btn {
      padding: 0px 30px;
      border-radius: 18px;
      height: 30px;
      color: $tab-bg-grey;
      background: #14121f;
      border-color: #14121f;
    }
  }

  .ant-select,
  .ant-cascader-picker {
    width: 100%;
    border-radius: 5px;
    /* height    : 33px; */
    border: 1px solid #80808047;
  }

  .ant-form-item-label {
    line-height: 24px;
  }

  .ant-select-selection {
    font-size: 14px;
    font-weight: 400;
  }

  .ant-input-number {
    width: 100% !important;
  }

  .ant-radio-wrapper {
    margin: 10px 10px 10px 10px;
    /* width: 100%; */
  }

  .ant-btn {
    background-color: #154479;
  }

  .ant-btn:hover {
    background-color: #154479;
  }
}

.cursor-pointer {
  height: 25px;
  width: fit-content;
  padding: 4px 7px;
  background-color: #1a3062;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  pointer-events: all !important;
}

.submit-btn {
  // position: fixed;
  // left: 50px;
  // top: 110px;
  width: max-content;
  margin-top: 10px !important;
  color: $primary-font-blue !important;
  border-color: $primary-font-blue !important;
  padding: 0 10px;
}

.ant-btn > .anticon {
  line-height: 0 !important;
}
