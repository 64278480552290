@import "../../constants/style-variables.scss";

.custom-filter-container {
  width: 149px;
  height: 54px;
  background-color: white;
  border-radius: 15px;
  padding: 4px 1px;
  box-shadow: 0px 2px 5px -3px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;

  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  .name {
    padding: 1px 11px;
    margin-bottom: -4px;
    color: $filter-head-grey;
    font-size: 11px;
  }

  .ant-select {
    width: 100%;
    font-weight: 500;
    height: 33px;
    overflow: hidden;
    padding: 0 10px;

    .ant-select-arrow svg {
      fill: $filter-blue;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: inherit;
    overflow: auto;
    margin-left: -10px;
    margin-right: 14px;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: $filter-blue !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
  }
}

.total-width {
  width: 100%;
  cursor: not-allowed;
  pointer-events: none;
}

.status-filter {
  width: 230px;
}

.status-filter-approval {
  width: 180px;
}

.select-date-container {
  width: 220px;

  .ant-picker {
    height: 32px;
    border: none;
    background-color: transparent;

    .ant-picker-input > input::placeholder {
      color: $filter-blue;
    }

    svg {
      fill: $filter-blue;
    }
  }

  .ant-picker-focused {
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
  }
}
