@import "../../constants/style-variables.scss";
.open-auction {
  align-items: center;
  display: flex;
  flex-direction: column;
  .label {
    padding: 0 10px;
  }
  .noteText {
    width: 100%;
    margin: 1px 0px 5px 5%;
    // padding-top: 12px;
    font-size: 12px;
    color: red;
  }
  .filter-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 15px;
    .refresh {
      position: fixed;
      left: 50px;
      top: 110px;
      width: max-content;
      color: $primary-font-blue;
      border-color: $primary-font-blue;
      padding: 0 10px;
    }
    .ant-btn > .anticon {
      line-height: 0;
    }

    .selected-wheat {
      background-color: white;
      border-radius: 10px;
      width: 120px;
      padding: 3px 10px;
      box-shadow: 0px 2px 5px -3px;

      .selected-wheat-label {
        color: #bdbcbc;
        font-size: 10px;
        font-weight: 500;

        .selected-wheat-value {
        }
      }
    }
  }
  .time-data-container {
    padding: 0 37px 0 30px;
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    font-weight: 300;
    margin-top: -15px;
    width: 96%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  .archives-table-container {
    padding: 0 15px 15px 15px;
    width: 100%;
  }
  .download-button-container {
    display: flex;
    justify-content: flex-end;
    width: 96%;
    .download-btn-styles {
      background-color: #154478;
      font-size: 13px;
      right: 10px;
    }
    .submit-btn {
      margin: 0 !important;
      right: 20px;
    }
  }
  .sauda-status {
    color: $orange;
    &.red {
      color: $red;
    }
    &.green {
      color: $green;
    }
    &.blue {
      color: blue;
    }
  }
}
.cancel-container {
  // width: 16px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid $red;
  color: $red;
  padding: 0 5px;
  width: 55px;
  text-align: center;
  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
}
.rebate-info {
  display: flex;
  justify-content: space-between;

  .info {
    width: 20px;
    height: 20px;
    background-color: #1a3062;
    border-radius: 10px;
    color: white;
    padding: 2px 7px;
    cursor: pointer;
    pointer-events: all !important;
  }
}

.rebate-modal {
  // .ant-modal-content {
  //   background-color: $tab-bg-grey;
  // }
  .ant-modal-body {
    margin-top: -64px;
  }
  .rebate-container {
    width: 100%;
    padding: 10px 0px;
    .ant-table table .ant-table-tbody > tr > td {
      padding: 1px 8px;
    }
    .ant-table-tbody tr td {
      border-bottom: 1px solid #959595;
      border-left: 1px solid #959595;
    }
    .ant-table table .ant-table-thead > tr:last-child > th {
      border-bottom: none;
    }
    .ant-table-tbody tr td:last-child {
      border-right: 1px solid #959595;
    }
    .ant-table-tbody td[rowspan] {
      // border-top: 1px solid rgb(73, 73, 73);
    }
  }
  .rebate-header {
    color: $primary-font-blue;
    font-size: 22px;
    margin-top: -10px;
    font-weight: 300;
  }
}
